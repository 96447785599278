.App {
  text-align: center;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  background: linear-gradient(135deg, #3fa1fb, #fc46a8);
}

body {
  padding: 0;
  margin: 0;
}

button {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
